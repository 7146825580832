import { Route,Routes,BrowserRouter as Router, Navigate } from "react-router-dom";
import Home from "./Components/Screens/Home";
import ContactUs from "./Components/ContactUs";
import AboutUsPage from "./Components/AboutUsPage"
function App()
{
return(<div>
  <Router>
  <Routes>
    <Route exact element={<Home/>} path="/"/>
    <Route exact element={<Home/>} path="/home"/>
    <Route exact element={<ContactUs/>} path="/contact"/>
    <Route exact element={<AboutUsPage/>} path="/about"/>
    <Route exact element={<Navigate to="/home"/>} path="*"/>
  </Routes>
  </Router>
  </div>)
}


export default App;
