import { Divider } from "@mui/material";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { IoLogoGoogleplus } from "react-icons/io";
import * as React from 'react';
import { IoMdArrowDropdown } from "react-icons/io";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useStyles } from "./Screens/ProjectCss/HoveredCss";

export default function Header()
{
  
  const Classes=useStyles()

  const matches = useMediaQuery("(max-width:960px)");

 
    const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

    return(<div style={{width:'100%'}}>
        {matches?<></>:<div style={{height:60,background:'#fff',display:'flex',alignItems:'center',justifyContent:'center'}}>
       <div style={{width:'94%',color:'#687693',fontSize:'15px',fontWeight:500,lineHeight:1,display:'flex',gap:14}}>
       <div >
       Phone: +99 (0) 101 0000 888
       </div>
       <div style={{width:20}}>
        <hr style={{transform:'rotate(90deg)'}}>
        </hr>
       </div>
       <div > 
        Email: noreply@yourdomain.com
        </div>
        <div style={{width:20}}>
        <hr style={{transform:'rotate(90deg)'}}>
        </hr>
       </div>
       <div  style={{gap:25,display:'flex',cursor:'pointer'}}>
       <div className={Classes.menuupper}>
       <FaTwitter />
       </div>
       <div className={Classes.menuupper}>
       <FaFacebookF />
       </div>
       <div className={Classes.menuupper}>
       <FaLinkedinIn />
       </div>
       <div className={Classes.menuupper}>
       <IoLogoGoogleplus />
       </div>
       </div>

       <div style={{marginLeft:'auto',gap:12,cursor:'pointer'}}>
      <div style={{display:'flex'}}
         onClick={handleClick}
         className={Classes.menuupper}>
     <div>English</div><div><IoMdArrowDropdown /></div>
      </div>
      <Menu 
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >

        <MenuItem 
        style={{  marginBottom: '3px',fontFamily:'Muli, sans-serif',color:'#687693',fontWeight:500  }}
         onClick={handleClose}>Hindi</MenuItem>

        <MenuItem  
        style={{  marginBottom: '3px',fontFamily:'Muli, sans-serif',color:'#687693',fontWeight:500  }} onClick={handleClose}>Arabic</MenuItem>

        <MenuItem  
        style={{  marginBottom: '3px',fontFamily:'Muli, sans-serif',color:'#687693',fontWeight:500 }}
        onClick={handleClose}>urdu</MenuItem>

      </Menu>
    </div>
    
    <div style={{width:20}}>
        <hr style={{transform:'rotate(90deg)'}}>
        </hr>
       </div>
       <div  className={Classes.menuupper}  style={{cursor:'pointer'}}>
       Subscribe Now
       </div>
       </div>
        </div>}


        <Divider style={{background:'#f5f6fa',opacity:'.3'}}/>
    </div>)
}