import director from "../assets/testimonial.png"
import InstagramIcon from '@mui/icons-material/Instagram';
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({

    dots:{
      '& .slick-dots li.slick-active button::before': {
        color:'#09cc7f',
        opacity:1
      },
      '& .slick-dots li button::before': {
        color:'#b2bec3',
        fontSize:'9px',
        opacity:0.4,
      },
      '& .slick-dots li ':{

        margin:'-44px -1px',
      },
      marginBottom:10,
  },
});

export default function OurExpert()
{

var useStyle=useStyles()
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
    autospeed:1,
    arrows:false
    
  };
  var data=[director,director,director]
  const showSlider=()=>{
    return data.map((item)=>{
      return (<div>
     <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
   <div  style={{ border:'solid 1px #fff',background:'#fff',width:'60%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center",height:matches?'auto':'auto',padding:3,borderRadius:10}}>
   <img src={item} style={{width:matches?100:'20%'}}></img>
    <div  style={{color:'#072366',fontSize:'21px',fontWeight:600,marginTop:'4%'}}>
    Margaret Lawson
    </div>
   <div style={{color:'#687693',fontSize:'16px',marginTop:'2%',fontWeight:450,display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
   Creative Director
   <div style={{display:'flex',flexDirection:'row',marginTop:'1%',justifyContent:'center',alignItems:'center'}}>
<InstagramIcon style={{fontSize:'17px'}}></InstagramIcon><div style={{fontSize:12,color:'#687693'}}>Linked in Profile</div>
</div>
<div style={{marginTop:'5%',fontStyle:'italic',fontSize:'24px',lineHeight:2.1,fontWeight:400,textAlign:'center'}}>
   “I am at an age where I just want to be fit and healthy our bodies are our responsibility! So start caring for your body and it will care for you. Eat clean it will care for you and workout hard.”
   </div>
   </div>
   </div>
</div>
      </div>)
    })
  }
    const matches = useMediaQuery("(max-width:960px)");
    return(<div className={useStyle.dots} style={{cursor:'pointer',height:matches?'auto':500,marginBottom:matches?'10%':'5%'}}>
    <Slider {...settings} >
     {showSlider()}
    </Slider>
 </div>)
}