import caseImage1 from '../assets/case1.png'
import caseImage2 from "../assets/case2.png"
import caseImage3 from '../assets/case3.png'
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useStyles } from "./Screens/ProjectCss/Container";

import CircularProgress, {
    circularProgressClasses,
  } from '@mui/material/CircularProgress';
  import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
  
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#09cc7f' : '#308fe8',
    },
  }));
  
  // Inspired by the former Facebook spinners.
  function FacebookCircularProgress(props) {
    return (
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800],
          }}
          size={40}
          thickness={4}
        
          value={1}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) => (theme.palette.mode === 'light' ? '#09cc7f' : '#308fe8'),
            animationDuration: '2000ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={40}
          thickness={4}
          {...props}
        />
      </Box>
    );
  }



export default function HelpingToTheWrold()
{
  const matches = useMediaQuery("(max-width:960px)");
    const Classes=useStyles()
    return(<div className={Classes.Container1}>
         <div  className={matches?Classes.Container02:Classes.Container2}>
         <div  className={Classes.Container3}>
          <div  className={Classes.Container4}>Our Cases You can See</div>
          <div  className={matches?Classes.Container05:Classes.Container5}>Explore Our Latest Causes That We Works</div>


      {/* box part */}

      
         <div  className={matches?Classes.Container06:Classes.Container6}>
   <div  style={{ border:'solid 1px #fff',background:'#fff',width:matches?'48vw':'24.5vw',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center",height:460,padding:3,borderRadius:10}}>
   <img src={caseImage1} style={{width:'100%'}}></img>
    <div  style={{color:'#072366',fontSize:'21px',fontWeight:600,marginTop:'10%'}}>
    Ensure Education For Every Poor Children
    </div>
   <div style={{width:'100%',marginTop:'5%',color:'#09cc7f',fontSize:matches?'2.4vw':15}}>
      <FacebookCircularProgress />
      <div style={{marginLeft:'60%',fontSize:matches?'2.4vw':15}}>70%</div>
      <BorderLinearProgress variant="determinate" value={70} style={{width:'95%'}} />
   </div>
   <div style={{color:'#072366',fontSize:'15px',marginRight:'auto',marginTop:'4%',fontWeight:400}}>
  Raised : $20,000
   </div>
   </div>

   <div  style={{ border:'solid 1px #fff',background:'#fff',width:matches?'48vw':'24.5vw',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center",height:460,padding:3,borderRadius:10}}>
   <img src={caseImage2} style={{width:'100%'}}></img>
    <div  style={{color:'#072366',fontSize:'21px',fontWeight:600,marginTop:'10%'}}>
    Ensure Education For Every Poor Children
    </div>
   <div style={{width:'100%',marginTop:'5%',color:'#09cc7f'}}>
      <FacebookCircularProgress />
      <div style={{marginLeft:'60%'}}>70%</div>
      <BorderLinearProgress variant="determinate" value={70} style={{width:'95%'}} />
   </div>
   <div style={{color:'#072366',fontSize:'15px',marginRight:'auto',marginTop:'4%',fontWeight:400}}>
  Raised : $20,000
   </div>
   </div>

   <div  style={{ border:'solid 1px #fff',background:'#fff',width:matches?'48vw':'24.5vw',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center",height:460,padding:3,borderRadius:10}}>
   <img src={caseImage3} style={{width:'100%'}}></img>
    <div  style={{color:'#072366',fontSize:'21px',fontWeight:600,marginTop:'10%'}}>
    Ensure Education For Every Poor Children
    </div>
   <div style={{width:'100%',marginTop:'5%',color:'#09cc7f'}}>
      <FacebookCircularProgress />
      <div style={{marginLeft:'60%'}}>70%</div>
      <BorderLinearProgress variant="determinate" value={70} style={{width:'95%'}} />
   </div>
   <div style={{color:'#072366',fontSize:'15px',marginRight:'auto',marginTop:'4%',fontWeight:400}}>
  Raised : $20,000
   </div>
   </div>

  
    </div>
    </div>
         </div>
    </div>)
}