import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import logo from "../assets/logo.png"
import useMediaQuery from "@mui/material/useMediaQuery";
import { IoMenu } from "react-icons/io5";
import Menu from '@mui/material/Menu';
import { IoAccessibilitySharp } from "react-icons/io5";
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './Screens/ProjectCss/HoveredCss';
import { IoClose } from "react-icons/io5";
import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { IoIosHome } from "react-icons/io";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import SubMenuIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { IoArchive } from "react-icons/io5";
export default function MainHeader()
{
  const Classes=useStyles()
    var navigate=useNavigate()
    const handleContact=()=>{
      navigate('/contact')
    }
    const handleBack=()=>{
        navigate('/home')
      }
      const handleAbout=()=>{
        navigate('/about')
      }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

 
    
    

    const [openone, setOpen] = useState(false);
    const [subMenuOpen, setSubMenuOpen] = useState(false);
  
    const toggleDrawer = (openStatus) => (event) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
      setOpen(openStatus);
    };
  
    const toggleSubMenu = () => {
      setSubMenuOpen(!subMenuOpen);
    };
  
    const toggleDrawerClose= () => {
      setOpen(false);
    };
  
    const handleSubMenuClick = () => {
      setOpen(false); // Drawer band karna
      // Yahaan kuch aur sub-menu logic ho sakta hai
    };


  const matches = useMediaQuery("(max-width:960px)");


    return(<div style={{width:'100%'}}>
  <div style={{position:'sticky'}}>
   <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ background:'#fff',
          height: 100,
          display: "flex",
          zIndex: 5,justifyContent:'center',padding:20}}>
        <Toolbar>
          <div onClick={handleBack} style={{cursor:'pointer'}}>
            <img src={logo} ></img>
          </div>
          {matches?<></>:<div style={{gap:40,color:"#072366",fontWeight:300,fontSize:'15px',display:'flex',marginLeft:'auto',cursor:'pointer'}}>
          <div style={{marginLeft:'auto',gap:12,cursor:'pointer'}}>
      <div style={{display:'flex'}}
         onClick={handleClick}
       
      >
     <div className={Classes.menu}>Home</div>
      </div>
      <Menu 
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        style={{marginTop:'1%',
        marginLeft:'-1%'}}>

        <MenuItem 
        style={{  marginBottom: '3px',fontFamily:'Muli, sans-serif',color:'#687693',fontWeight:500  }}
         onClick={handleClose}>Home</MenuItem>

        <MenuItem  
        style={{  marginBottom: '3px',fontFamily:'Muli, sans-serif',color:'#687693',fontWeight:500  }} onClick={handleClose}>Leader</MenuItem>

        <MenuItem 
        style={{  marginBottom: '3px',fontFamily:'Muli, sans-serif',color:'#687693',fontWeight:500 }}
        onClick={handleClose}>Mission</MenuItem>

          <MenuItem 
        style={{  marginBottom: '3px',fontFamily:'Muli, sans-serif',color:'#687693',fontWeight:500 }}
        onClick={handleClose}>Vission</MenuItem>

      </Menu>
    </div>
          <div className={Classes.menu} onClick={handleAbout}>
           About
          </div>
          <div className={Classes.menu}>
          Latest Couses
          </div>
          <div className={Classes.menu}>
          Social Event
          </div>
          <div className={Classes.menu}>
          Blog
          </div>
          <div className={Classes.menu} onClick={handleContact} >
           Contact
          </div>
          </div>}
          <div style={{border:'solid 1px #09cc7f',borderColor:'#09cc7f',background:'#09cc7f',width:'10%',display:'flex',justifyContent:'center',alignItems:'center',padding:20,marginLeft:matches?'auto':'4%',cursor:'pointer'}}>Donate</div>


          {matches?<div key={"right"} onClick={toggleDrawer(true)} style={{marginLeft:'auto',padding:10,cursor:'pointer'}}> 
          <IoMenu style={{color:'#09cc7f',fontSize:'35px'}}/>
 <Drawer anchor={'right'} open={openone} onClose={toggleDrawer(false)} >
        <List >
          <ListItem button onClick={toggleSubMenu}>
            <ListItemIcon>
              <IoIosHome style={{fontSize:'19px',color:'#072366'}} />
            </ListItemIcon>
            <ListItemText primary={'Home'} style={{color:'#072366'}}/>
            <IconButton>
              <SubMenuIcon/>
            </IconButton>
          </ListItem>
          {subMenuOpen && (
            <List>
              <ListItem button onClick={handleSubMenuClick}>
                <ListItemText inset primary={'Cause'} style={{color:'#072366'}}/>
              </ListItem>
              <ListItem button onClick={handleSubMenuClick}>
                <ListItemText inset primary={'Leader'} style={{color:'#072366'}}/>
              </ListItem>
              <ListItem button onClick={handleSubMenuClick}>
                <ListItemText inset primary={'Mission'} style={{color:'#072366'}}/>
              </ListItem>
              <ListItem button onClick={handleSubMenuClick}>
                <ListItemText inset primary={'Vission'} style={{color:'#072366'}}/>
              </ListItem>
            </List>
          )}
          <ListItem button onClick={handleSubMenuClick}>
            <ListItemIcon>
            <IoArchive style={{fontSize:'19px',color:'#072366'}} />
            </ListItemIcon>
            <ListItemText primary={'About Us'} style={{color:'#072366'}}/>
          </ListItem>
          <ListItem button onClick={handleSubMenuClick}>
            <ListItemIcon>
            <IoIosHome style={{fontSize:'19px',color:'#072366'}} />
            </ListItemIcon>
            <ListItemText primary={'latest cause'} style={{color:'#072366'}}/>
          </ListItem>
          <ListItem button onClick={handleSubMenuClick}>
            <ListItemIcon>
            <IoArchive style={{fontSize:'19px',color:'#072366'}} />
            </ListItemIcon>
            <ListItemText primary={'Social Event'} style={{color:'#072366'}}/>
          </ListItem>
          <ListItem button onClick={handleSubMenuClick}>
            <ListItemIcon>
            <IoIosHome style={{fontSize:'19px',color:'#072366'}} />
            </ListItemIcon>
            <ListItemText primary={'Blog'} style={{color:'#072366'}}/>
          </ListItem>
          <ListItem button onClick={handleSubMenuClick}>
            <ListItemIcon>
            <IoArchive style={{fontSize:'19px',color:'#072366'}} />
            </ListItemIcon>
            <ListItemText primary={'Contact US'} style={{color:'#072366'}}/>
          </ListItem>
        </List>
        <IconButton onFocus={toggleDrawerClose} >
        <div style={{color:'green',fontSize:'16px'}}>Close</div>
        </IconButton>
        
      </Drawer>

          </div>:<></>}
        </Toolbar>
      </AppBar>
    </Box>
    </div>
    </div>)
}