import { makeStyles } from "@mui/styles";

const useStylevibrate = makeStyles ({
    Cleanboxvibrate: {
        // Define initial styles here if needed
      },
    Cleanboxvibrate: {
    animation: '$vibrate 4s linear infinite', // Apply the vibration animation
  },
  '@keyframes vibrate': {
    '0%': { transform: 'translateX(0)' }, // Initial position
    '20%': { transform: 'translateX(-5px)' }, // Move left
    '40%': { transform: 'translateX(5px)' }, // Move right
    '60%': { transform: 'translateX(-5px)' }, // Move left
    '80%': { transform: 'translateX(5px)' }, // Move right
    '100%': { transform: 'translateX(0)' }, // Back to initial position
  },
})
export {useStylevibrate}