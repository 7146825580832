import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import banner1 from "../assets/banner1.png"
import banner2 from '../assets/banner2.jpg'
export default function MainSlider()
{

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
    autospeed:1,
    arrows:false
    
  };

  var data=[banner1,banner2]
  const showSlider=()=>{
    return data.map((item)=>{
      return (<div style={{background:'red'}}>
        <img src={item} style={{width:'100%'}}></img>
      </div>)
    })
  }
  return(<div  style={{width:'100%',cursor:'pointer'}}>
     <Slider {...settings} >
      {showSlider()}
     </Slider>
  </div>) 
}