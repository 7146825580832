import { GrSystem } from "react-icons/gr";
import { IoSettingsOutline } from "react-icons/io5";
import { RxAccessibility } from "react-icons/rx";
import { useStyles } from "./Screens/ProjectCss/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useStylevibrate } from "./Screens/ProjectCss/VibrateCss";
import { useState } from "react";
export default function HelpingToTheWrold()
{

    const [isHovered, setIsHovered] = useState(false);
  
    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
   const classvibrate=useStylevibrate()
    const matches = useMediaQuery("(max-width:800px)");
    const Classes=useStyles()
    return(<div className={Classes.Container1}>
         <div  className={matches?Classes.Container02:Classes.Container2}>
         <div  className={Classes.Container3}>
          <div  className={Classes.Container4}>What We Are Doing</div>
          <div  className={matches?Classes.Container05:Classes.Container5}>We Are In A Mission To Help The Helpless</div>


      {/* box part */}

      
     <div  className={matches?Classes.Container06:Classes.Container6}>
        <div   className={`${classvibrate.Cleanboxvibrate} ${isHovered ? classvibrate.vibratingAnimation : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
   <div className={matches?Classes.Container07:Classes.Container7}>
   <div  className={Classes.Container8}><GrSystem style={{fontSize:'40px'}}/></div>
    <div  className={Classes.Container9}>
    Clean Water
    </div>
    <div  className={Classes.Container10}>
    The sea freight service has grown conside rably in recent years. We spend timetting to know your processes to.
    </div>
   </div>
   </div>

   <div   className={`${classvibrate.Cleanboxvibrate} ${isHovered ? classvibrate.Cleanboxvibrate: ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
   <div  className={matches?Classes.Container07:Classes.Container7}>
   <div className={Classes.Container8}><IoSettingsOutline  style={{fontSize:'40px'}}/></div>
    <div className={Classes.Container9}>
    Clean Water
    </div>
    <div  className={Classes.Container10}>
    The sea freight service has grown conside rably in recent years. We spend timetting to know your processes to.
    </div>
   </div>
</div>

<div    className={`${classvibrate.Cleanboxvibrate} ${isHovered ? classvibrate.vibratingAnimation : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
   <div  className={matches?Classes.Container07:Classes.Container7}>
   <div  className={Classes.Container8}><RxAccessibility style={{fontSize:'40px'}}/></div>
    <div  className={Classes.Container9}>
    Clean Water
    </div>
    <div className={Classes.Container10}>
    The sea freight service has grown conside rably in recent years. We spend timetting to know your processes to.
    </div>
   </div>
</div>
  
    </div>
    </div>
         </div>
    </div>)
}