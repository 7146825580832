import { TextField } from "@mui/material"
import Footerimage from "../assets/Footerimage.png"
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
export default function Footer()
{
    const theme = useTheme();
    const matches = useMediaQuery("(max-width:800px)");
    return(<div style={{width:'100%',background:'#192a56',height:matches?'auto':400,opacity:'2',display:'flex',flexDirection:matches?'column':'row'}}>
         <div style={{width:matches?'100%':'25%',marginTop:'8%'}}>
          <div style={{display:'flex',justifyContent:'center'}}>
          <img src={Footerimage}></img>
          </div>
         </div>
         <div style={{width:matches?'100%':'25%',display:'flex',justifyContent:'center',marginTop:'8%'}}>
          <div style={{display:'flex',flexDirection:"column"}}>
          <div style={{fontSize:'20px',fontWeight:400,color:'#fff',display:matches?'flex':'normal',justifyContent:matches?'center':'normal'}}>
          Contact Info
          </div>
          <div style={{fontSize:'16px',fontWeight:300,color:'#868c98',lineHeight:'40px',marginTop:'10%'}}>
         <div>
         Address :Your address goes here,
         </div>
         <div>
         your demo address to town address.
         </div>
         <div>
         Phone : +8880 44338899 567
         </div>
         <div>
         Email : info@colorlibrayesofindia.com
         </div>
          </div>
          </div>
         </div>

         <div style={{width:matches?'100%':'25%',display:'flex',justifyContent:'center',marginTop:'8%'}}>
          <div style={{display:'flex',flexDirection:"column"}}>
          <div style={{fontSize:'20px',fontWeight:400,color:'#fff'}}>
          Important Link
          </div>
          <div style={{fontSize:'16px',fontWeight:300,color:'#868c98',lineHeight:'40px',marginTop:'10%'}}>
         <div>
         View Project
         </div>
         <div>
         Contact Us
         </div>
         <div>
         Testimonial
         </div>
         <div>
         Testimonial
         </div>
         <div>
         Support to us
         </div>
          </div>
          </div>
         </div>

         <div style={{width:matches?'100%':'25%',display:'flex',justifyContent:'center',marginTop:'8%'}}>
          <div style={{display:'flex',flexDirection:"column"}}>
          <div style={{fontSize:'20px',fontWeight:400,color:'#fff',display:matches?'flex':'normal',justifyContent:matches?'center':'normal'}}>
          Newsletter
          </div>
          <div style={{fontSize:'16px',fontWeight:300,color:'#868c98',lineHeight:'40px',marginTop:'10%'}}>
         <div style={{padding:matches?10:0}}>
         Heaven fruitful doesn't over lesser in days. Appear creeping.
         </div>
        <div style={{marginTop:'8%',display:matches?'flex':'normal',justifyContent:matches?'center':'normal'}}>
            <TextField size="small" placeholder="Enter Email ID" style={{background:'#fff',width:'18vw',borderRadius:'3%',color:'#000'}}/>
        </div>
          </div>
          </div>
         </div>
    </div>)
}