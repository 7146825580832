import Header from "../Header"
import MainHeader from "../MainHeader"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStyles } from "./ProjectCss/RootCss";
import MainSlider from "../MainSlider";
import HelpingToTheWrold from "../HelpingToTheWorld";
import CleanWater from "../CleanWater";
import AboutUS from "../AboutUs";
import OurCases from "../OurCases"
import OurExpert from "../OurExperts";
import AboutDirector from "../AboutDirector"
import Footer from "../Footer";
export default function Home()
{
    const Classes=useStyles()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return(<div className={Classes.RootCss1}>
        <div>
            <Header/>
        </div>
        <div style={{position:'sticky',top:'0',zIndex:'5'}}>
            <MainHeader/>
        </div>
        <div>
       <MainSlider/>
        </div>
        <div>
      <HelpingToTheWrold/>
        </div>
        <div>
        <CleanWater/>
        </div>
        <div>
            <AboutUS/>
        </div>
    <div>
        <OurCases/>
    </div>
    <div>
        <OurExpert/>
    </div>
    <div>
        <AboutDirector/>
    </div>
   <div>
    <Footer/>
   </div>
    </div>)
}