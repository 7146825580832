import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
    Container1:{
        width:'100%'
    },
    Container2:{
        height:800,background:'#fff',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'
    }, 
    Container02:{
        height:"auto",background:'#fff',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'
    }, 
    Container3:{
        display:'flex',alignItems:'center',width:'80%',justifyContent:'center',padding:10,flexDirection:'column'
    }, Container4:{
        fontSize:'18px',fontWeight:600,color:'#09cc7f'
    },
     Container5:{
        fontSize:'50px',fontWeight:700,color:'#072366',width:'63%',textAlign:'center',marginTop:'3%'
    }, 
    Container05:{
        fontSize:'6vw',fontWeight:700,color:'#072366',width:'100%',textAlign:'center',marginTop:'3%'
    }, 
    Container6:{
        display:'flex',justifyContent:'center',gap:30,flexDirection:"row",marginTop:'6%'
    }, 
    Container06:{
        display:'flex',justifyContent:'center',gap:30,flexDirection:"column",marginTop:'6%'
    },
    Container7:{
        border:'solid 1px #09cc7f',background:'#09cc7f',width:'24.5vw',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center",height:460,padding:3,borderRadius:10,cursor:'pointer'
    },
    Container07:{
        border:'solid 1px #09cc7f',background:'#09cc7f',width:'50vw',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center",height:460,padding:3,borderRadius:10
    },
     Container8:{
        border:'solid 1px #fff',background:'#fff',width:90,height:84,padding:3,borderRadius:'50%',display:'flex',justifyContent:'center',alignItems:'center',color:'#09cc7f'
    }, Container9:{
        fontSize:'25px',fontWeight:500,color:'#fff',marginTop:'15%'
    }, Container10:{
        color:'#fff',fontSize:'16px',marginTop:"8%",lineHeight:'30px',textAlign:'center',padding:10
    },
  
})

export {useStyles}