import Footer from "./Footer";
import Header from "./Header";
import MainHeader from "./MainHeader";
import { useStyles } from "./Screens/ProjectCss/RootCss";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BsTelephoneInboundFill } from "react-icons/bs";
import AboutUs1 from "../assets/AboutusImage1.png"
import AboutUs2 from "../assets/AboutusImage2.png"
export default function About()
{
    const matches = useMediaQuery("(max-width:960px)");
    const Classes=useStyles()
    return(<div className={Classes.RootCss1}  >
      <div>
        <Header/>
      </div>
      <div style={{position:'sticky',top:'0',zIndex:'5'}}>
      <MainHeader/>
      </div>
      <div style={{width:'100%',height:180,fontSize:'50px',color:'#072366',fontWeight:600,display:'flex',justifyContent:'center',alignItems:'center'}}>
     <div style={{}}>About Us</div>
      </div>

      <div style={{width:'100%',marginTop:matches?'10%':'1%'}}>
         <div style={{height:'auto',background:'#fff',display:'flex',justifyContent:'center'}}>
         <div style={{display:'flex',alignItems:'center',width:'80%',flexDirection:matches?'column':'row'}}>
            <div style={{width:matches?'100%':'50%'}}>
         <div  style={{ fontSize:'18px',fontWeight:600,color:'#09cc7f'}}>About Our Foundation</div>
          <div style={{fontSize:matches?'6vw':'42px',fontWeight:700,color:'#002d5b',lineHeight:'60px',marginTop:'6%'}}>We Are In A Mission To  Help Helpless</div>
          <div style={{display:'flex',flexDirection:'column'}}>
          <div style={{fontSize:'16px',color:'#464d65',lineHeight:'30px',marginTop:'4%'}}>Lorem ipsum dolor sit amet, consectetur adipisicing elit,mod tempor incididunt ut labore et dolore magna aliqua. Utnixm, quis nostrud exercitation ullamc.</div>
          <div style={{display:'flex',gap:40}}>
          <div style={{border:'solid 1px #09cc7f',borderColor:'#09cc7f',background:'#09cc7f',width:'25%',display:'flex',justifyContent:'center',alignItems:'center',padding:20,color:'#fff',marginTop:'6%'}}>About Us</div>
       <div style={{marginTop:'9%'}}>
       <BsTelephoneInboundFill style={{color:'#09cc7f',fontSize:'24px'}}/>
       </div>
       <div style={{marginTop:'9%'}}>
       <div style={{fontSize:'16px',color:'#072366',lineHeight:'30px',fontWeight:500,}}>+91 9878679982</div>
       </div>
        </div>
        </div>
         </div>

         <div style={{width:matches?"100%":'50%',
         display:'flex',
         marginLeft:'10%',
         justifyContent:matches?'center':'normal',
         marginTop:matches?'14%':'0%'}}>
        <div style={{
        marginTop:'auto',
        marginRight:'-20%',
        zIndex:1
        }}>
          <img src={AboutUs1} style={{width:matches?100:'90%'}}></img>
            </div>
          <div >
          <img src={AboutUs2} style={{width:matches?200:'85%'}}></img>
          </div>
         </div>
         </div>
         </div>
    </div>

    


<div style={{marginTop:'10%'}}>
    <Footer/>
</div>

    </div>)
}


