import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({

  menu: {
    color: '#072366',
    '&:hover': {
      color: '#09cc7f', 
    },
  },
  menuupper: {
    color: '#687693', // Default color
    '&:hover': {
      color: '#09cc7f', // Color when hovered
    },
  },
 menu3: {
    background: '#fff', // Default color
    '&:hover': {
      background: '#09cc7f', // Color when hovered
    },
  },
})

export { useStyles };