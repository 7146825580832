import Footer from "./Footer";
import Header from "./Header";
import MainHeader from "./MainHeader";
import { useStyles } from "./Screens/ProjectCss/RootCss";
import { TextField,Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Map, GoogleApiWrapper} from 'google-maps-react'
 function ContactUs(props)
{
    const matches = useMediaQuery("(max-width:1100px)");
    const Classes=useStyles()
    return(<div className={Classes.RootCss1}>
      <div>
        <Header/>
      </div>
      <div style={{position:'sticky',top:'0',zIndex:'5'}}>
      <MainHeader/>
      </div>
      <div style={{width:'100%',height:150,fontSize:'50px',color:'#072366',fontWeight:600,display:'flex',justifyContent:'center',alignItems:'center'}}>
     <div style={{}}>Contact Us</div>
      </div>
      <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
     <div style={{width:"80%",height:'auto',marginTop:'2%',flexDirection:'row',display:'flex'}}>
     <div style={{width:matches?'1800%':'60%',height:400,display:'flex',justifyContent:'center'}}>
     <div >
       
       <div>
       <div style={{fontSize:14,color:'#072366'}}>
             Your first name*
           </div>
           <TextField
             id="standard-password-input"
             type="password"
             autoComplete="current-password"
             variant="standard" size="small" style={{width:matches?'50vw':'60ch',background:'#f1f2f6',marginTop:'1%'}}  />
       </div>
       
       <div style={{marginTop:'1%'}}>
       <div style={{fontSize:14,color:'#072366'}}>
             Your Email*
           </div>
           <TextField
             id="standard-password-input"
             type="password"
             autoComplete="current-password"
             variant="standard"  size="small" style={{width:matches?'50vw':'60ch',marginTop:'1%',background:'#f1f2f6'}}  />
       </div>
      
       <div style={{marginTop:'1%'}}>
       <div style={{fontSize:14,color:'#072366'}}>
           Your Phone Number*
           </div>
           <TextField
             id="standard-password-input"
             type="password"
             autoComplete="current-password"
             variant="standard"   size="small" style={{width:matches?'50vw':'60ch',marginTop:'1%',background:'#f1f2f6'}}  />
       </div>
      
       <div style={{marginTop:'1%'}}>
       <div style={{fontSize:14,color:'#072366'}}>
             Your Massege*
           </div>
           <TextField
             id="standard-password-input"
             type="password"
             autoComplete="current-password"
             variant="standard"  style={{width:matches?'50vw':'60ch',marginTop:'1%',background:'#f1f2f6'}}  />
       </div>
       <div style={{display:'flex',flexDirection:'row',marginTop:'3%',gap:7}}>
       
       <div style={{marginTop:'1%'}}>
       <div style={{fontSize:14,color:'#072366'}}>
           Your State*
           </div>
           <TextField
             id="standard-password-input"
             type="password"
             autoComplete="current-password"
             variant="standard"  size="small"  style={{width:matches?'24vw':'30ch',background:'#f1f2f6',marginTop:'1%'}}  />
       </div>
   
       <div style={{marginTop:'1%'}}>
       <div style={{fontSize:14,color:'#072366'}}>
           Your City*
           </div>
           <TextField
             id="standard-password-input"
             type="password"
             autoComplete="current-password"
             variant="standard"  size="small"  style={{width:matches?'25vw':'30ch',background:'#f1f2f6',color:'#fff',marginTop:'1%'}}  />
       </div>
       </div>
       <div style={{marginTop:'4%',marginBottom:'5%'}}>
           <Button style={{borderColor:'#687693',width:'100%',color:'#687693',fontWeight:'bold',textTransform:'none',padding:5}} fullWidth variant="outlined" >Submit</Button>
       </div>
       </div>
     </div>
     <div style={{width:'40%',height:400,display:'flex',justifyContent:'center',textAlign:'center',lineHeight:'30px'}}>
     {matches?<></>:<div  >
        <div style={{fontSize:18,color:'#09cc7f'}}>
         All GENERAL QUERIES FOR GIRLS
        </div>
        <div style={{fontSize:15,color:'#072366',marginTop:'2%'}}>
          info@helplineforgirls.org
        </div>
        <div style={{fontSize:18,marginTop:'5%',color:'#09cc7f'}}>
          DONATE RELATED QUERIES
        </div>
        <div style={{fontSize:15,color:'#072366',marginTop:'2%'}}>
         Ankit singh Narwariya -7867566789
        </div>
        <div style={{fontSize:15,color:'#072366',marginTop:'2%'}}>
        donation@helplineforgirls.org
        </div>
        <div style={{fontSize:18,marginTop:'5%',color:'#09cc7f'}}>
          FOR EXISTING DONAR
        </div>
        <div style={{fontSize:15,color:'#072366',marginTop:'2%'}}>
         Satyveer singh -7867566789
        </div>
        <div style={{fontSize:18,marginTop:'5%',color:'#09cc7f'}}>
          FOR MEDIA RELATED QUERIES
        </div>
        <div style={{fontSize:15,color:'#072366',marginTop:'2%'}}>
        Write to sandep nayak at - 9988778899
        </div>
        <div style={{fontSize:15,color:'#072366',marginTop:'2%'}}>
        Media@helplineforgirls.org
        </div>
        <div style={{fontSize:15,color:'#072366',marginTop:'2%'}}>
      sandeepsappalnayak2gmail.com
        </div>
      </div>}
        </div>
     </div>
      </div>


      <div style={{marginTop:'5%'}}>       
<Map
   style={{height:matches?'auto':400,width:'100%'}}
      google={props.google}
      zoom={14}
      initialCenter={{
        lat: 37.774929,
        lng: -122.419416
      }}
    />
      </div>


{matches?<></>:<div style={{marginTop:'43%'}}>
    <Footer/>
</div>}

    </div>)
}


export default GoogleApiWrapper({
    apiKey: ("AIzaSyBGOdKlt0ubdxaB32U7A0pb0T20m7cnt44")
  })(ContactUs)