import { BsTelephoneInboundFill } from "react-icons/bs";
import useMediaQuery from "@mui/material/useMediaQuery";
export default function HelpingToTheWrold()
{
    const matches = useMediaQuery("(max-width:960px)");

    return(<div style={{width:'100%'}}>
         <div style={{height:matches?400:400,background:'#fff',display:'flex',justifyContent:'center'}}>
         <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'80%',flexDirection:matches?'column':'row'}}>
          <div style={{fontSize:matches?'6vw':'65px',fontWeight:780,color:'#002d5b'}}>Our Helping To The World.</div>
          <div style={{display:'flex',flexDirection:'column'}}>
          <div style={{fontSize:'16px',color:'#464d65',lineHeight:'30px'}}>Onsectetur adipiscing elit, sed do eiusmod tempor incididunt ut bore et dolore magnt, sed do eiusmod.</div>
          <div style={{display:'flex',gap:40}}>
          <div style={{border:'solid 1px #09cc7f',borderColor:'#09cc7f',background:'#09cc7f',width:'25%',display:'flex',justifyContent:'center',alignItems:'center',padding:20,color:'#fff',marginTop:'6%'}}>Donate</div>
       <div style={{marginTop:'9%'}}>
       <BsTelephoneInboundFill style={{color:'#09cc7f',fontSize:'24px'}}/>
       </div>
       <div style={{marginTop:'9%'}}>
       <div style={{fontSize:'16px',color:'#072366',lineHeight:'30px',fontWeight:500}}>+12 1325 13</div>
       </div>
        </div>
        </div>
         </div>
         </div>
    </div>)
}