import team1 from "../assets/team1.png"
import team2 from "../assets/team2.png"
import team3 from "../assets/team3.png"
import team4 from "../assets/team4.png"
import { useStyles } from "./Screens/ProjectCss/Container";
import InstagramIcon from '@mui/icons-material/Instagram';
import useMediaQuery from "@mui/material/useMediaQuery";
export default function OurExpert()
{
   const matches = useMediaQuery("(max-width:960px)");
    const Classes=useStyles()
    return(<div className={Classes.Container1}>
         <div  className={matches?Classes.Container02:Classes.Container2}>
         <div  className={Classes.Container3}>
          <div  className={Classes.Container4}>What We Are Dong</div>
          <div  className={matches?Classes.Container05:Classes.Container5}>Our Expert Volunteer Alwyes Ready</div>


      {/* box part */}

      
         <div  className={matches?Classes.Container06:Classes.Container6}>
   <div  style={{ border:'solid 1px #fff',background:'#fff',width:matches?'40vw':'16.2vw',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center",height:460,padding:3,borderRadius:10}}>
   <img src={team1} style={{width:'100%'}}></img>
    <div  style={{color:'#072366',fontSize:'21px',fontWeight:600,marginTop:'10%'}}>
    Bruce Roberts
    </div>
   <div style={{color:'#687693',fontSize:'15px',marginTop:'4%',fontWeight:400}}>
   <div >
   Volunteer leader
    </div>
   <div style={{display:'flex',flexDirection:'row',marginTop:'3%',justifyContent:'center',alignItems:'center'}}>
<InstagramIcon style={{fontSize:'17px'}}></InstagramIcon><div style={{fontSize:10,color:'#687693'}}>Linked in Profile</div>
</div>
   </div>
   </div>

   <div  style={{ border:'solid 1px #fff',background:'#fff',width:matches?'40vw':'16.2vw',height:460,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center",padding:3,borderRadius:10}}>
   <img src={team2} style={{width:'100%'}}></img>
    <div  style={{color:'#072366',fontSize:'21px',fontWeight:600,marginTop:'10%'}}>
    Robart Rechard
    </div>
   <div style={{color:'#687693',fontSize:'15px',marginTop:'4%',fontWeight:400,}}>
   <div  >
   Volunteer leader
    </div>
   <div style={{display:'flex',flexDirection:'row',marginTop:'3%',justifyContent:'center',alignItems:'center'}}>
<InstagramIcon style={{fontSize:'17px'}}></InstagramIcon><div style={{fontSize:10,color:'#687693'}}>Linked in Profile</div>
</div>
   </div>
   </div>

   <div  style={{ border:'solid 1px #fff',background:'#fff',width:matches?'40vw':'16.2vw',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center",height:460,padding:3,borderRadius:10}}>
   <img src={team3} style={{width:'100%'}}></img>
    <div  style={{color:'#072366',fontSize:'21px',fontWeight:600,marginTop:'10%'}}>
    Brendon Tailor
    </div>
   <div style={{color:'#687693',fontSize:'15px',marginTop:'4%',fontWeight:400}}>
   <div  >
   Volunteer leader
    </div>
   <div style={{display:'flex',flexDirection:'row',marginTop:'3%',justifyContent:'center',alignItems:'center'}}>
<InstagramIcon style={{fontSize:'17px'}}></InstagramIcon><div style={{fontSize:10,color:'#687693'}}>Linked in Profile</div>
</div>
   </div>
   </div>

   <div  style={{ border:'solid 1px #fff',background:'#fff',width:matches?'40vw':'16.2vw',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center",height:460,padding:3,borderRadius:10}}>
   <img src={team4} style={{width:'100%'}}></img>
    <div  style={{color:'#072366',fontSize:'21px',fontWeight:600,marginTop:'10%'}}>
    Brendon Tailor
    </div>
   <div style={{color:'#687693',fontSize:'15px',marginTop:'4%',fontWeight:400}}>
   <div  >
   Volunteer leader
    </div>
   <div style={{display:'flex',flexDirection:'row',marginTop:'3%',justifyContent:'center',alignItems:'center'}}>
<InstagramIcon style={{fontSize:'17px'}}></InstagramIcon><div style={{fontSize:10,color:'#687693'}}>Linked in Profile</div>
</div>
   </div>
   </div>

  
    </div>
    </div>
         </div>
    </div>)
}